import React from 'react';
import Items from '../components/Items';
import Banner from '../components/Banner.js';



const Home = () => {
  return (
    <>
      <Banner />
      <div className='container mx-auto relative'>
       
      </div>
      
      <Items /> 
    </>
  )
};

export default Home;