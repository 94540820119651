import g1 from './assets/img/games/1.jpg';
import g2 from './assets/img/games/2.jpg';
import g3 from './assets/img/games/3.jpg';
import g4 from './assets/img/games/4.jpg';
import g5 from './assets/img/games/5.jpg';
import g6 from './assets/img/games/6.jpg';
import g10 from './assets/img/accounts/10.jpg';
import g11 from './assets/img/accounts/11.jpg';
import g12 from './assets/img/accounts/12.jpg';
import g13 from './assets/img/gifts/13.jpg';
import g14 from './assets/img/gifts/14.jpg';
import g15 from './assets/img/gifts/15.jpg';


export const ItemData = [

{
    id: 1,
    "name": "God of War Ragnar\u00f6k",
    "capacity1": 399,
    "capacity2": 799,
    "capacity3": 1499,
    "console": "PlayStation",
    "picture": g1,
    "ps4": 0,
    "ps5": 1,
    "IGN": '9/10',
    "metacritic": '9/10',
    "type": "game",
    "description": 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.',
  },

{
    id: 2,
    "name": "The Last of Us Part II Remastered",
    "capacity1": 399,
    "capacity2": 799,
    "capacity3": 1499,
    "console": "PlayStation",
    "picture": g2,
    "ps4": 1,
    "ps5": 1,
    "IGN": '9/10',
    "metacritic": '9/10',
    "type": "game",
    "description": 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.',
  },

{
    id: 3,
    "name": "Tekken 8",
    "capacity1": 399,
    "capacity2": 799,
    "capacity3": 1499,
    "console": "PlayStation",
    "picture": g3,
    "ps4": 0,
    "ps5": 1,
    "IGN": '9/10',
    "metacritic": '9/10',
    "type": "game",
    "description": 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.',
  },

{
    id: 4,
    "name": "Mortal Kombat 1",
    "capacity1": 399,
    "capacity2": 799,
    "capacity3": 1499,
    "console": "PlayStation",
    "picture": g4,
    "ps4": 1,
    "ps5": 1,
    "IGN": '9/10',
    "metacritic": '9/10',
    "type": "game",
    "description": 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.',
  },


{
    id: 5,
    "name": "Demon's Souls",
    "capacity1": 399,
    "capacity2": 799,
    "capacity3": 1499,
    "console": "PlayStation",
    "picture": g5,
    "ps4": 1,
    "ps5": 1,
    "IGN": '9/10',
    "metacritic": '9/10',
    "type": "game",
    "description": 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.',
  },

{
    id: 6,
    "name": "GHOST OF TSUSHIMA",
    "capacity1": 399,
    "capacity2": 799,
    "capacity3": 1499,
    "console": "PlayStation",
    "picture": g6,
    "ps4": 1,
    "ps5": 1,
    "IGN": '9/10',
    "metacritic": '9/10',
    "type": "game",
    "description": 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.',
  },

{
    "id": 10,
    "prcie1M": 9.99,
    "prcie3M": 24.99,
    "prcie12M": 79.99,
    "console": "PlayStation",
    "name": "PlayStation Plus Essential",
    "picture": g10,
    "ps4": 1,
    "ps5": 1,
    "Region": "United States",
    "type": "account",
    "sort": "اسنشیال"
  },
  {
    "id": 11,
    "prcie1M": 14.99,
    "prcie3M": 39.99,
    "prcie12M": 134.99,
    "console": "PlayStation",
    "name": "PlayStation Plus Extra",
    "picture": g11,
    "ps4": 1,
    "ps5": 1,
    "Region": "United States",
    "type": "account",
    "sort": "اکسترا"
  },

  {
    "id": 12,
    "prcie1M": 17.99,
    "prcie3M": 49.99,
    "prcie12M": 159.99,
    "console": "PlayStation",
    "name": "PlayStation Plus Premium",
    "picture": g12,
    "ps4": 1,
    "ps5": 1,
    "Region": "United States",
    "type": "account",
    "sort": "پرمیوم"
  },

{
    "id": 13,
    "price1": 10,
    "price2": 25,
    "price3": 50,
    "price4": 100,
    "console": "PlayStation",
    "name": "PlayStation Network ",
    "picture": g13,
    "rating": "None",
    "type": "gift"
  },
  {
    "id": 14,
    "price1": 10,
    "price2": 25,
    "price3": 50,
    "price4": 100,
    "console": "Xbox",
    "name": "Xbox",
    "picture": g14,
    "rating": "None",
    "type": "gift"
  },
  {
    "id": 15,
    "price1": 10,
    "price2": 25,
    "price3": 50,
    "price4": 100,
    "console": "Steam",
    "name": "Steam",
    "picture": g15,
    "rating": "None",
    "type": "gift"
  }
];
