import React, { useState, createContext } from 'react';
import { ItemData } from '../Data';


export const ItemContext = createContext();

const ItemProvider = ({ children }) => {

  const [items, setItems] = useState(ItemData);
  const [product, setProduct] = useState('دسته‌بندی (همه)');
  const [type, setType] = useState('کنسول (همه)');



  return (
    <ItemContext.Provider value={{ items, setItems, product, setProduct, type, setType }}>
      {children}
    </ItemContext.Provider>
  );
};

export default ItemProvider;

