import React, { useContext } from 'react';
import Item from '../components/Item';
import { ItemContext } from '../context/ItemContext';


const Items = () => {

  const { items } = useContext(ItemContext);

  return (
    <section className='py-8 bg-gradient-to-br from-blue-900 via-blue-400 to-green-300'>
      <div className='container mx-auto lg:px-0'>
        <div className='grid grid-cols-1 max-w-sm mx-auto gap-[30px] flex-row-reverse
          lg:grid-cols-3 lg:max-w-none lg:mx-0 md:grid-cols-2 md:max-w-none md:mx_auto'>
          {items.map((item) => {
            
            return <Item item={item} key={item.id} />
          })}
        </div>
      </div>

    </section>
  );
};

export default Items;
