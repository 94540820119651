import React from 'react';
import logomd from '../assets/logomd.png';


const Footer = () => {
  return (
    <footer className='bg-black py-12 rounded'>
      <div className='container mx-auto text-white flex justify-between '>
        <a href='/'>
          <img  className='my-auto -translate-y-7' src={logomd} alt='' />
        </a>
        Copyright  &copy; {new Date().getFullYear()} - All Rights Reserved.
        
      </div>
    </footer>
  )
};

export default Footer;