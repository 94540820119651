import React from 'react';
import bg from '../assets/bg1.jpg'


const Banner = () => {
  return (

    <div className='heroSlider h-[360px] md:h-[480px] lg:h-[600px] bg-blue-300'>
      <div className='h-full bg-violet-400 relative flex justify-center items-center'>
        
        <div className='z-20 text-white text-center lg:pr-[100px] mt-[100px] lg:mt-0 lg:mr-[40%]'>
          <div className='p-0 font-primary'>اکانت قانونی پلی استیشن</div>
          <hi className='text-[45px] lg:text-[60px] font-primary'>خرید سریع و مطمئن</hi>
        </div>

        <div className='absolute top-0 w-full h-full'>
          <img className='h-full w-full object-cover' src={bg} alt='' />
        </div>

        <div className='absolute w-full h-full bg-black/50'></div>

      </div>

    </div>

  )
};

export default Banner;