import React from 'react';
import { Link } from 'react-router-dom';
import { IoGameControllerOutline } from "react-icons/io5";
import { RiAccountCircleLine } from "react-icons/ri";
import { MdCardGiftcard } from "react-icons/md";
import { SiPlaystation5, SiPlaystation4} from "react-icons/si";



const Item = ({ item }) => {
  const {id, name, type, ps5, ps4, picture, console, IGN, metacritic, capacity1, capacity2, capacity3, prcie1M, 
          prcie3M, prcie12M, sort, Region, price1, price2, price3, price4} = item;


  const product = {
    game: 'بازی',
    account: 'اکانت',
    gift: 'کارت هدیه',
  };

  const iconType = {
    game: <IoGameControllerOutline />,
    account: <RiAccountCircleLine />,
    gift: <MdCardGiftcard/>,
  };

  return (
    <div className='bg-white shadow-2xl min-h-[500px] rounded-b-md'>

      <div className='overflow-hidden'>
        <Link to={`/item/${id}`}>
        <img className='group-hover:scale-120 transition-all duration-300 w-full' src={picture} alt="" />
        </Link>
      </div>

      <div className='bg-white shadow-lg max-w-[300px] mx-auto h-[75px] -translate-y-1/3 items-center flex flex-col rounded-md'>
        <div className='text-violet-700 flex flex-row items-center justify-center gap-x-10 my-2'>
          <div className='text-[25px]'>
            {iconType[type]}
          </div>
          <div className='font-primary font-semibold text-[20px]'>
            {product[type]}
          </div>
        </div>

        <div className='justify-center uppercase font-tertiary tracking-[1px] font-semibold text-lg'>
          {name}
        </div>
      </div>
      
      <div className='mb-6'>
        {type === 'game' && (
          <div>
            <div className=' flex flex-row items-center justify-center gap-x-10 h-[20px] mx-auto'>
              {ps5 === 1 && <SiPlaystation5 className='text-[60px]' />}
              {ps4 === 1 && <SiPlaystation4 className='text-[60px]' />}
            
            </div>
            <div className='flex flex-row justify-center gap-x-10 px-25 mt-5'>
              <div>IGN: {IGN}</div>
              <div>META: {metacritic}</div>
            </div>
            
            <div className='flex flex-col text-right pr-[15px] text-gray-700 justify-center items-center pt-5'>
              <div className='flex flex-row-reverse'>
                <div className='font-primary text-[18px font-semibold]'> :ظرفیت ۱ </div>
                <div className='font-semibold text-violet-600 mx-2 '> {capacity1 && `${capacity1}`} </div>
                <div className='font-primary text-[18px]'> هزار تومان </div>
              </div>
              <div className='flex flex-row-reverse'>
                <div className='font-primary text-[18px font-semibold]'> :ظرفیت ۲ </div>
                <div className='font-semibold text-violet-600 mx-2'> {capacity2 && `${capacity2}`} </div>
                <div className='font-primary text-[18px]'> هزار تومان </div>
              </div>
              <div className='flex flex-row-reverse'>
                <div className='font-primary text-[18px font-semibold]'> :ظرفیت ۳ </div>
                <div className='font-semibold text-violet-600 mx-2'> {capacity3 && `${capacity3}`} </div>
                <div className='font-primary text-[18px]'> هزار تومان </div>
              </div>
            </div>

          </div>
        )
        } 

        {type === 'account' &&
          <div>
             <div className=' flex flex-row items-center justify-center gap-x-10 h-[20px] mx-auto'>
              {ps5 === 1 && <SiPlaystation5 className='text-[60px]' />}
              {ps4 === 1 && <SiPlaystation4 className='text-[60px]' />}
            </div>

            <div className="mt-5 flex text-right flex-col pr-15 items-center">
              <div className='font-primary text-[18px]'>
              اکانت پلی استیشن {sort}
              </div>

              <div className='font-primary text-[18px]'> {Region} :منطقه</div>

            </div>


            <div className='flex flex-col text-right pr-[15px] text-gray-700 mt-5 items-center align'>
              <div className='flex flex-row-reverse'>
                <div className='font-primary text-[18px]'>۱ </div>
                <div className='font-primary text-[18px]'>:ماهه</div>
                <div className='font-semibold text-violet-600 mx-2'>$ {prcie1M && `${prcie1M}`}</div>
              </div>
              <div className='flex flex-row-reverse'>
              <div className='font-primary text-[18px]'>۳ </div>
                <div className='font-primary text-[18px]'>:ماهه</div>
                <div className='font-semibold text-violet-600 mx-2'>$ {prcie3M && `${prcie3M}`}</div>
              </div>
              <div className='flex flex-row-reverse'>
              <div className='font-primary text-[18px]'>۱۲</div>
                <div className='font-primary text-[18px]'>:ماهه</div>
                <div className='font-semibold text-violet-600 mx-2'>$ {prcie12M && `${prcie12M}`}</div>
              </div>
            </div>

          </div>
        } 

        {type === 'gift' &&
          <div className='flex flex-col items-center font-tertiary uppercase tracking-[1px] '>
            <div> {console}</div>
            <div>$ {price1}, {price2}, {price3}, {price4}, ...</div>
          </div>
      }
      </div>
      
      <div className='flex flex-row pb-4 mx-5 items-center justify-end gap-x-3'>
              <Link to={`/room/${id}`} 
                className='text-[15px] border border-blue-800 rounded-md text-blue-900 bg-gray-100 h-10 flex-1 transition-all px-8 flex justify-center items-center'>مشاهده</Link>
              <Link to={'https://telegram.org'} className='text-[15px] rounded-md text-white bg-blue-900 h-10 flex-1 transition-all px-8 flex justify-center items-center'>سفارش</Link>
      </div>

    </div>
  )
};

export default Item;
