import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import { ItemContext } from '../context/ItemContext'; 
import { FaCheck } from 'react-icons/fa';
import { SiPlaystation5, SiPlaystation4} from "react-icons/si";


const RoomDetails = () => {
  const { items } = useContext(ItemContext);
  const { id } = useParams();
  
  const item = items.find((item) => {
    return item.id === Number(id)
  })

  const { name, description, type, ps5, ps4, picture, console, IGN, metacritic, capacity1, capacity2, capacity3, prcie1M, 
    prcie3M, prcie12M, sort, Region, price1, price2, price3, price4 } = item;

  return (
    <section>
      <ScrollToTop />
      <div className='bg-room bg-cover bg-center h-[560px] relative flex justify-center items-center'>
        <div className='absolute w-full h-full bg-black/70'></div>
        <h1 className='text-white text-6xl z-20 font-primary text-center'>{name}</h1>

      </div>

      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row h-full py-24'>
          <div className='w-full h-full lg:w-[60%] px-6 bg-yellow-100'>
            <h2 className='h2'>{name}</h2>
            <p className='mb-8'>{description}</p>
            <img className='mb-8' src={picture} alt='' />
            
            <div className='mt-12'>
              <h3 className='h3 mb-3'>Item Facilities</h3>
              <p className='mb-12'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam voluptatum ea accusamus, corrupti nostrum eum placeat quibusdam quis beatae quae labore earum architecto aliquid debitis.
              </p>

            </div>

          </div>

          <div className='w-full h-full lg:w-[40%]'>
            <div className='px-6 py-8 bg-accent/10 mb-12'>
              <div className='flex flex-col space-y-4 mb-4 justify-center items-center'>
                <h3 className='uppercase font-tertiary tracking-[1px] font-semibold text-lg'>
                  {name}
                </h3>

                <div className='mb-6'>
                  {type === 'game' && (
                    <div>
                      <div className=' flex flex-row items-center justify-center gap-x-10 h-[20px] mx-auto'>
                        {ps5 === 1 && <SiPlaystation5 className='text-[60px]' />}
                        {ps4 === 1 && <SiPlaystation4 className='text-[60px]' />}
                      
                      </div>
                      <div className='flex flex-row justify-center gap-x-10 px-25 mt-5'>
                        <div>IGN: {IGN}</div>
                        <div>META: {metacritic}</div>
                      </div>
                      
                      <div className='flex flex-col text-right pr-[15px] text-gray-700 justify-center items-center pt-5'>
                        <div className='flex flex-row-reverse'>
                          <div className='font-primary text-[18px font-semibold]'> :ظرفیت ۱ </div>
                          <div className='font-semibold text-violet-600 mx-2 '> {capacity1 && `${capacity1}`} </div>
                          <div className='font-primary text-[18px]'> هزار تومان </div>
                        </div>
                        <div className='flex flex-row-reverse'>
                          <div className='font-primary text-[18px font-semibold]'> :ظرفیت ۲ </div>
                          <div className='font-semibold text-violet-600 mx-2'> {capacity2 && `${capacity2}`} </div>
                          <div className='font-primary text-[18px]'> هزار تومان </div>
                        </div>
                        <div className='flex flex-row-reverse'>
                          <div className='font-primary text-[18px font-semibold]'> :ظرفیت ۳ </div>
                          <div className='font-semibold text-violet-600 mx-2'> {capacity3 && `${capacity3}`} </div>
                          <div className='font-primary text-[18px]'> هزار تومان </div>
                        </div>
                      </div>

                    </div>
                  )
                  } 

                  {type === 'account' &&
                    <div>
                      <div className=' flex flex-row items-center justify-center gap-x-10 h-[20px] mx-auto'>
                        {ps5 === 1 && <SiPlaystation5 className='text-[60px]' />}
                        {ps4 === 1 && <SiPlaystation4 className='text-[60px]' />}
                      </div>

                      <div className="mt-5 flex text-right flex-col pr-15 items-center">
                        <div className='font-primary text-[18px]'>
                        اکانت پلی استیشن {sort}
                        </div>

                        <div className='font-primary text-[18px]'> {Region} :منطقه</div>

                      </div>


                      <div className='flex flex-col text-right pr-[15px] text-gray-700 mt-5 items-center align'>
                        <div className='flex flex-row-reverse'>
                          <div className='font-primary text-[18px]'>۱ </div>
                          <div className='font-primary text-[18px]'>:ماهه</div>
                          <div className='font-semibold text-violet-600 mx-2'>$ {prcie1M && `${prcie1M}`}</div>
                        </div>
                        <div className='flex flex-row-reverse'>
                        <div className='font-primary text-[18px]'>۳ </div>
                          <div className='font-primary text-[18px]'>:ماهه</div>
                          <div className='font-semibold text-violet-600 mx-2'>$ {prcie3M && `${prcie3M}`}</div>
                        </div>
                        <div className='flex flex-row-reverse'>
                        <div className='font-primary text-[18px]'>۱۲</div>
                          <div className='font-primary text-[18px]'>:ماهه</div>
                          <div className='font-semibold text-violet-600 mx-2'>$ {prcie12M && `${prcie12M}`}</div>
                        </div>
                      </div>

                    </div>
                  } 

        {type === 'gift' &&
          <div className='flex flex-col items-center font-tertiary uppercase tracking-[1px] '>
            <div> {console}</div>
            <div>$ {price1}, {price2}, {price3}, {price4}, ...</div>
          </div>
        }
        </div>


                <button className='hover:bg-blue-900 h-[60px] w-full bg-blue-700 text-[15px] tracking-[3px] font-tertiary uppercase text-white flex-1 transition-all px-8 flex justify-center items-center'>
                  Book Now
                </button>
                
                
              </div>
            </div>

            <div>
              <h3 className='h3 font-primary'>قوانین خرید</h3>
              <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.Ea placeat eos sed voluptas unde veniam eligendi a. Quaerat molestiae hic omnis temporibus quos consequuntur nam
              </p>
              <ul>
                <li className='flex items-center gap-x-4'>
                  <FaCheck className='text-green-900'/>
                  rule 1
                </li>

                <li className='flex items-center gap-x-4'>
                  <FaCheck className='text-green-900'/>
                  rule 2
                </li>

                <li className='flex items-center gap-x-4'>
                  <FaCheck className='text-green-900'/>
                  rule 3
                </li>

                <li className='flex items-center gap-x-4'>
                  <FaCheck className='text-green-900'/>
                  rule 4
                </li>
              </ul>
            </div>
            
          </div>

        </div>
      </div>
    </section>
  )
};

export default RoomDetails;