import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ItemDetails from './pages/ItemDetails';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';



const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/item/:id',
    element: <ItemDetails/>
  },
]);



const App = () => {
  return (
    <div>
        <Header />
        <RouterProvider router={router} />
        <Footer />
      
    </div>
  );
};

export default App;
